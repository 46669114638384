<script setup>
    import { onMounted, ref } from 'vue';
    import dsOPerson from 'o365.modules.dataObjects.dsOPerson.ts';
    import { getOrCreateDataObject } from 'o365.vue.ts';
    import Procedure from 'o365.modules.Procedure.ts';
    import OMediaQueryProvider from 'o365.vue.components.MediaQueryProvider.vue';
    import OMatchMedia from 'o365.vue.components.MatchMedia.vue';
    import { userSession, app } from 'o365.modules.configs.ts';
    import $t from 'o365.modules.translate.ts';
    
    const props = defineProps({
        showTitle:{
            type:Boolean,
            default:false
        }
    });

    const personNotificationEmails = ref(null);
    const itemTypes = ref(new Array());
    const itemsOrgUnits = ref(new Array());
    const emailConfig = ref(new Array());
    const notifyItems = ref(new Array());
    const oldGenUrl = document.querySelector('meta[name="o365-site-oldgenurl"]')?.content;
    
    var itemsStart = 0;
    var notifyUnread = null; 
    var isFilterSettingsLoaded = false;
    var isEmailSettingsLoaded = false;
    var personData = null;

    const dsTEmailConfig = getOrCreateDataObject({"id":"dsTEmailConfig","viewName":"sviw_Notify_EmailConfig2","distinctRows":false,"uniqueTable":null,"allowUpdate":false,"allowInsert":false,"allowDelete":false,"appendData":false,"selectFirstRowOnLoad":true,"fields":[{"name":"ID","sortOrder":null,"sortDirection":null,"groupByOrder":null,"groupByAggregate":null},{"name":"Name","sortOrder":null,"sortDirection":null,"groupByOrder":null,"groupByAggregate":null},{"name":"SortOrder","sortOrder":1,"sortDirection":"asc","groupByOrder":null,"groupByAggregate":null}],"masterDataObject_ID":null,"masterDetailDefinition":[],"clientSideHandler":false,"maxRecords":-1,"dynamicLoading":false,"whereClause":null,"filterString":null});
    const dsTItemTypes = getOrCreateDataObject({"id":"dsTItemTypes","viewName":"sviw_Notify_MyItemTypes","distinctRows":false,"uniqueTable":null,"allowUpdate":false,"allowInsert":false,"allowDelete":false,"appendData":false,"selectFirstRowOnLoad":true,"fields":[{"name":"ItemType","sortOrder":1,"sortDirection":"asc","groupByOrder":null,"groupByAggregate":null},{"name":"ItemType_ID","sortOrder":1,"sortDirection":"asc","groupByOrder":null,"groupByAggregate":null},{"name":"Selected","sortOrder":null,"sortDirection":null,"groupByOrder":null,"groupByAggregate":null},{"name":"EmailSelected","sortOrder":null,"sortDirection":null,"groupByOrder":null,"groupByAggregate":null},{"name":"PrimKey","sortOrder":null,"sortDirection":null,"groupByOrder":null,"groupByAggregate":null}],"masterDataObject_ID":null,"masterDetailDefinition":[],"clientSideHandler":false,"maxRecords":25,"dynamicLoading":false,"whereClause":"","filterString":null});
    const dsTItemOrgUnits = getOrCreateDataObject({
        "id": "dsTItemOrgUnits",
        "viewName": "sviw_Notify_MyOrgUnits",
        "distinctRows": false,
        "uniqueTable": null,
        "allowUpdate": false,
        "allowInsert": false,
        "allowDelete": false,
        "appendData": false,
        "selectFirstRowOnLoad": true,
        "fields": [
            {
                "name": "OrgUnit_ID",
                "sortOrder": null,
                "sortDirection": null,
                "groupByOrder": null,
                "groupByAggregate": null
            },
            {
                "name": "NumOfItems",
                "sortOrder": 1,
                "sortDirection": "desc",
                "groupByOrder": null,
                "groupByAggregate": null
            },
            {
                "name": "OrgUnit",
                "sortOrder": null,
                "sortDirection": null,
                "groupByOrder": null,
                "groupByAggregate": null
            },
            {
                "name": "OrgUnitSelected",
                "sortOrder": null,
                "sortDirection": null,
                "groupByOrder": null,
                "groupByAggregate": null
            }
        ],
        "masterDataObject_ID": null,
        "masterDetailDefinition": [],
        "clientSideHandler": false,
        "maxRecords": 12,
        "dynamicLoading": false,
        "whereClause": "",
        "filterString": null
    })

    const messagesButton = ref('o365-messages-button');

    const procLoadMessages = new Procedure({id: "procLoadMessages", procedureName: "sstp_Notify_ItemsAndUpdates"});
    const procMarkAsRead = new Procedure({ id: "procMarkAsRead", procedureName: "sstp_Notify_ItemsUpdatePersonsMarkAsRead" });
    const procItemTypesFilAdd = new Procedure({ id: "procItemTypesFilAdd", procedureName: "sstp_Notify_ItemTypesFilterAdd"});
    const procItemTypesFilRem = new Procedure({ id: "procItemTypesFilRem", procedureName: "sstp_Notify_ItemTypesFilterRemove" });
    const procItemTypesEmaAdd = new Procedure({ id: "procItemTypesEmaAdd", procedureName: "sstp_Notify_ItemTypesEmailConfigAdd" });
    const procItemTypesEmaRem = new Procedure({ id: "procItemTypesEmaRem", procedureName: "sstp_Notify_ItemTypesEmailConfigRemove" });
    const procNotifyOrgUnitRem = new Procedure({ id: "procNotifyOrgUnitRem", procedureName: "sstp_Notify_OrgUnitsFilterRemove" });
    const procNotifyOrgUnitAdd = new Procedure({id: "procNotifyOrgUnitAdd", procedureName: "sstp_Notify_OrgUnitsFilterAdd" });
    const procSetNotification = new Procedure({ id: "procSetNotification", procedureName: "sstp_System_PersonSetNotification" });

    async function setFilter(){
        if(!isFilterSettingsLoaded){
            isFilterSettingsLoaded = true;

            personData = await dsOPerson.load();

            dsTItemTypes.load().then(data => {
                itemTypes.value = data;
            });

            dsTItemOrgUnits.load().then(data => {
                itemsOrgUnits.value = data;
            });
        }
    }

    async function setEmail() {
        if(!isEmailSettingsLoaded){
            isEmailSettingsLoaded = true;

            personData = await dsOPerson.load();

            personNotificationEmails.value = personData[0].NotificationEmails;

            dsTEmailConfig.load().then(data => {
                emailConfig.value = data;
            });

            dsTItemTypes.load().then(data => {
                itemTypes.value = data;
            });
        }
    }

    async function toggleEmail(item){
        if(item.EmailSelected === false){
            await procItemTypesEmaAdd.execute({ItemType_ID: item.ItemType_ID });
        } else {
            await procItemTypesEmaRem.execute({ItemType_ID: item.ItemType_ID });
        }

        dsTItemTypes.load().then(data => {
            itemTypes.value = data;
        });
    }

    async function toggleFilter(item){
        if(item.Selected === false){
            await procItemTypesFilAdd.execute({ItemType_ID: item.ItemType_ID });
        } else {
            await procItemTypesFilRem.execute({ItemType_ID: item.ItemType_ID });
        }

        dsTItemTypes.load().then(data => {
            itemTypes.value = data;
        });
    }

    async function toggleOrgUnit(item){
        if(item.OrgUnitSelected === false){
            await procNotifyOrgUnitAdd.execute({OrgUnit_ID: item.OrgUnit_ID });
        } else {
            await procNotifyOrgUnitRem.execute({OrgUnit_ID: item.OrgUnit_ID });
        }

        dsTItemTypes.load().then(data => {
            itemTypes.value = data;
        });
    }

    function showMoreNotifyUpdates(e){
        e.stopPropagation();
        e.preventDefault();

        var smalls = e.currentTarget.parentElement.querySelectorAll('.notify-update');
        
        e.currentTarget.remove();
        
        smalls.forEach(x => {
            x.classList.remove('d-none')
        });
    }

    async function loadNotifications(){
        var data = await procLoadMessages.execute({
            RowStart: itemsStart,
            UnRead: notifyUnread
        });

        var items = data[Object.keys(data)[0]];
        var updates = data[Object.keys(data)[1]];
        
        itemsStart = data[Object.keys(data)[2]][0].RowStart;
        
        items.forEach(x => {
            x.notifyUpdates = updates.filter(y => y.Item_ID === x.ID);
            notifyItems.value.push(x);
        });
    }

    function setUnread(){
        const notificationList = document.getElementById('notifications');
        
        notificationList.scrollTop = 0;
        notifyUnread = false;
        
        resetAndReload();
    }

    function setAll(){
        const notificationList = document.getElementById('notifications');
        
        notificationList.scrollTop = 0;
        notifyUnread = null;
        
        resetAndReload();
    }

    function resetAndReload(){
        notifyItems.value = new Array();
        itemsStart = 0;
        
        loadNotifications();
    }

    async function markAllNotificationsAsRead(){
        await procMarkAsRead.execute();
        
        resetAndReload();
    }

    async function setEmailConfig(item){
        await procSetNotification.execute({ ID: item.ID });
        
        dsOPerson.load();
    }

    onMounted(() => {
        var notifyPanel = document.getElementById('notificationsOffCanvas');
        
        notifyPanel.addEventListener('show.bs.offcanvas', function () {
            loadNotifications();
        });
        
        const notificationList = document.getElementById('notifications');
        
        var timeout = null;
        
        notificationList.addEventListener('scroll', e => {
            if((notificationList.scrollHeight - notificationList.scrollTop) - (notificationList.clientHeight) < 200) {
                clearTimeout(timeout);
                timeout = setTimeout(function(){
                    loadNotifications();
                }, 100);
            }
        });
    });
</script>

<template>
<OMediaQueryProvider>
<OMatchMedia v-slot="{ isMobileOrTablet }">
    <a class="nav-link active" style="cursor:pointer;" ref="messagesButton" data-bs-toggle="offcanvas" href="#notificationsOffCanvas">
        <i class="bi bi-bell-fill"></i>
        <span class="ms-2" v-if="showTitle">
            {{ $t("Messages") }}
        </span>
    </a>

    <Teleport to="body">
        <div class="offcanvas offcanvas-end" style="width:600px;" tabindex="-1" id="notificationsOffCanvas">
            <div>
                <span class="btn btn-sm btn-outline-primary m-2" @click="markAllNotificationsAsRead()" data-bs-dismiss="offcanvas" style="pointer: cursor;" v-if="isMobileOrTablet">
                    {{ $t('Mark All as read') }}
                </span>
                <button type="button" class="btn-close text-reset float-end p-2" v-if="isMobileOrTablet" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                <div class="nav nav-tabs d-flex flex-nowrap" id="myTab" role="tablist">
                    <div class="nav-item text-truncate" role="presentation">
                        <button class="nav-link active w-100 text-truncate" id="notifications-tab" @click="setAll()" data-bs-toggle="tab" data-bs-target="#notifications" type="button"
                            role="tab" aria-controls="notifications" aria-selected="true">{{ $t('Notifications') }}</button>
                    </div>
                    <div class="nav-item text-truncate" role="presentation">
                        <a class="nav-link text-truncate" id="unread-tab" @click="setUnread()" data-bs-toggle="tab" data-bs-target="#notifications" type="button"
                            role="tab" aria-controls="notifications" aria-selected="false">{{ $t('Unread') }}</a>
                    </div>
                    <div class="nav-item" role="presentation">
                        <button class="nav-link" id="filter-tab" @click="setFilter()" data-bs-toggle="tab" data-bs-target="#filter" type="button"
                            role="tab" aria-controls="filter" aria-selected="false">
                            <i class="bi bi-funnel-fill"></i>
                            <span class="ms-2" v-if="!isMobileOrTablet">{{ $t('Filter') }}</span>
                        </button>
                    </div>
                    <div class="nav-item" role="presentation">
                        <button class="nav-link" id="email-tab" @click="setEmail()" data-bs-toggle="tab" data-bs-target="#email" type="button"
                            role="tab" aria-controls="email" aria-selected="false">
                            <i class="bi bi-envelope-fill"></i>
                            <span class="ms-2" v-if="!isMobileOrTablet">{{ $t('Email') }}</span>
                        </button>
                    </div>
                    <div class="d-flex ms-auto me-3 justify-content-center align-items-center" v-if="!isMobileOrTablet">
                        <span class="btn btn-sm btn-outline-primary" @click="markAllNotificationsAsRead()" data-bs-dismiss="offcanvas" style="pointer: cursor;">
                            {{ $t('Mark All as read') }}
                        </span>
                    </div>
                </div>
                <div class="tab-content" id="myTabContent">
                    <div class="tab-pane fade show active" id="notifications" role="tabpanel" aria-labelledby="notifications-tab"
                        style="height:calc(100vh - 42px);overflow-y:auto;overflow-x:hidden;">
                        <div class="d-flex" v-for="(item, index) in notifyItems" :key="index">
                            <a class="d-flex flex-column px-3 pt-2 text-decoration-none position-relative w-100" :href="`${(!app.IsNextGenApp ? oldGenUrl : '')}/${item.URL}`">
                                <div class="mb-1">
                                    <i v-if="!item.IsRead" class="bi bi-circle-fill position-absolute text-primary" style="right:15px;font-size: 70%;top:10px;"></i>
                                    <h6 :class="[{'fw-normal': item.IsRead}]" class="mb-0 text-primary" style="width:80%">
                                        {{ item.ItemType }}: {{ item.Name }}
                                    </h6>
                                    <small class="text-muted">
                                        <p class="mb-0" style="width:80%;">{{ $t('Org Unit') }}: {{ item.OrgUnit }}</p>
                                    </small>
                                </div>
                                <div>
                                    <div v-for="(update, i) in item.notifyUpdates"> 
                                        <small class="d-flex notify-update" :class="[{'d-none': i > 4}]">
                                            <div>
                                                <span>{{ update.UpdateTypeText }}</span>  <span class="text-muted">{{ update.TimeDifference }}</span>
                                            </div>
                                        </small>
                                    </div>
                                    <button v-if="item.notifyUpdates.length > 4"  @click="showMoreNotifyUpdates($event)" class="btn btn-link btn-sm text-decoration-none ps-0" style="font-size:80%;font-weight:400;">Show more...</button>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="filter" role="tabpanel" aria-labelledby="filter-tab">
                        <div class="container-fluid">
                            <div class="row">
                                <h6 class="mt-2 mb-0">{{ $t('Filter Notification Bar by Type') }}</h6>
                                <label class="ms-0 mb-2">{{ $t('No selection = all notifications shown') }}</label>
                                <div v-for="item in itemTypes" class="col-4">
                                    <label style="font-size:small;white-space:nowrap;overflow: hidden;text-overflow: ellipsis;  width: 100%;">
                                        <input type="checkbox" @change="toggleFilter(item)" :checked="item.Selected">
                                        <span style="margin-left:5px">{{ item.ItemType }}</span>
                                    </label>
                                </div>
                                <h6 class="mt-2 mb-0">{{ $t('Filter Notification Bar by OrgUnit') }}</h6>
                                <label class="ms-0 mb-2">{{ $t('No selection = all notifications shown') }}</label>
                                <div v-for="item in itemsOrgUnits" class="col-4">
                                    <label style="font-size:small;white-space:nowrap;overflow: hidden;text-overflow: ellipsis;  width: 100%;">
                                        <input type="checkbox" @change="toggleOrgUnit(item)" :checked="item.OrgUnitSelected">
                                        <span style="margin-left:5px">{{ item.OrgUnit }}</span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="email" role="tabpanel" aria-labelledby="email-tab">
                        <div class="container-fluid">
                            <div class="flex-1 my-2 p-2 border bg-light-subtle" style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;border-radius:8px">
                                <template v-for="item in emailConfig">
                                    <label class="d-block" style="font-size:large!important;">
                                        <input type="radio" 
                                        name="notify-emailconfig" 
                                        @change="setEmailConfig(item)"
                                        :id="`email-config-${item.ID}`"
                                        :checked="item.ID === personNotificationEmails"> {{ item.Name }}
                                    </label>
                                </template>
                            </div>
                            <div class="row">
                                <h6 class="mt-2 mb-0">{{ $t('Restrict Immediate E-mails') }}</h6>
                                <label class="ms-0 mb-2">{{ $t('No selection = all notifications sent') }}</label>
                                <div v-for="item in itemTypes" class="col-4">
                                    <label style="font-size:small;white-space:nowrap;overflow: hidden;text-overflow: ellipsis;  width: 100%;">
                                        <input type="checkbox" @change="toggleEmail(item)" :checked="item.EmailSelected">
                                        <span style="margin-left:5px">{{ item.ItemType }}</span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Teleport>
</OMatchMedia>
</OMediaQueryProvider>
</template>
